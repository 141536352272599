// components
import Notes from './Notes'
import {IconLink} from './IconLink'
import Breadcrumbs from './Breadcrumbs'

// styles
import { ModuleTitle, ModuleHeader, ModuleHeaderBack, ModuleVolunteers } from '../styles/moduleNotes'
import { ScheduleTime } from '../styles/scheduleEntryStyle'
import { HeaderLinks } from '../styles/notesPage'

function ModuleNotes(props){

  // console.log('Module Notes', { props })
   // breadcrumb
   const breadcrumb = <Breadcrumbs
    course = { props.courseTitle }
    courseLink = '/course/advanced-project.2025-03-17'
    dayText = { `${props.dayTitle}` }
    dayLink = { `/day/${props.dayLink}` }
    moduleText = { props.title }
  />


  return(
    <>
       <ModuleHeader className="module-header">
         <div className="">
            <ModuleHeaderBack
              to={ location => `/day/${props.dayLink}`}
            >Back to {props.dayTitle}</ModuleHeaderBack>
            <ModuleTitle>{ props.title }</ModuleTitle>
            <ScheduleTime className="module-time">
              { props.start  &&
                <li>Start: <b>{ props.start }</b></li>
              }

              { props.time &&
                <li>Time: <b>{ props.time }</b></li>
              }
            </ScheduleTime>
          </div>
          {(props.blueJeans || props.tmate || props.jupyter) &&
            <HeaderLinks>
              { props.blueJeans &&
                <IconLink
                  text="BlueJeans"
                  icon="video"
                  link={ props.blueJeans }
                  target="_blank"
                />
              }
              { props.tmate &&
                <IconLink
                  text="tmate"
                  icon="terminal"
                  link={ props.tmate }
                  target="_blank"
                />
              }
              { props.jupyter &&
                <IconLink
                  text="Jupyter"
                  icon="python"
                  link={ props.jupyter }
                  target="_blank"
                />
              }
            </HeaderLinks>
          }
        </ModuleHeader>

        { breadcrumb }

        <Notes
          before = {
            <ModuleVolunteers className="module-volunteers">
              { props.volunteer &&
                <li>Volunteer: { props.volunteer }</li>
              }
              { props.noteTaker &&
                <li>Note Taker: { props.noteTaker }</li>
              }
            </ModuleVolunteers>
          }
          update = { true }
          type="notes"
        >
          { props.url }
        </Notes>
        {props.endUrl &&
          <Notes type="notes">{props.endUrl}</Notes>
        }
    </>
  )
}

export default ModuleNotes
